import "./Pagination.css";
import { Link } from "react-router-dom";

const Pagination = ({ page, pageSize, lastPage }) => {
  const prevPage = page - 1 || 1;
  const nextPage = page + 1 > lastPage ? page : page + 1;

  return (
    <div className="pagination">
      <Link to={`/txs/${pageSize}/1`} className="button" disabled={page === 1}>
        First
      </Link>
      <Link to={`/txs/${pageSize}/${prevPage}`} disabled={page === 1}>
        &lsaquo;
      </Link>
      <Link to={`/txs`} disabled className="pagination-pages">
        Page {page} of {lastPage}
      </Link>
      <Link to={`/txs/${pageSize}/${nextPage}`} disabled={page === lastPage}>
        &rsaquo;
      </Link>
      <Link to={`/txs/${pageSize}/${lastPage}`} disabled={page === lastPage}>
        Last
      </Link>
    </div>
  );
};

export default Pagination;
