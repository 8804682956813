import { Link } from "react-router-dom";

import "./BridgeNow.css";

const BridgeNow = () => (
  <div className="bridge-now">
    <Link className="button" to="/bridge">
      Bridge now
    </Link>
  </div>
);

export default BridgeNow;
