import { useState } from "react";
import cn from "classnames";

import "./Generate.css";
import { DEBUG, INFO_BRIDGE, MAGIC, PREFIX } from "../../config";
import { puzzle_hash_to_address } from "../../helpers/chiaHelper";
import { toChecksumAddress } from "../../helpers/web3Helper";
import Page from "../Page/Page";
import Tooltip from "../Tooltip/Tooltip";

const COPY_TEXT = "Copy to clipboard";
const COPIED_TEXT = "Copied";

const Generate = () => {
  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [bridgeAddress, setBridgeAddress] = useState("");
  const [copy, setCopy] = useState(COPY_TEXT);

  const changeAddress = (e) => {
    setAddress(e.target.value);
    setBridgeAddress("");
    setAddressError(false);
  };

  const generateBridgeAddress = () => {
    try {
      const checksumAddress = toChecksumAddress(address);
      const puzzleHash = MAGIC + checksumAddress.substring(2);
      setBridgeAddress(puzzle_hash_to_address(puzzleHash, PREFIX));
    } catch (e) {
      DEBUG && console.error("transactionApi.exception", e);
      setAddressError(true);
    }
  };

  return (
    <Page headline="Bridge now" breadcrumb info={INFO_BRIDGE}>
      <div className="overflow-y-auto">
        <table className="generate-table">
          <tbody>
            <tr>
              <th>Your receiving EVM address (starts with 0x):</th>
              <td>
                <input
                  type="text"
                  value={address}
                  onChange={changeAddress}
                  className={cn({ "input-error": address && addressError })}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="generate-button">
        <input
          type="button"
          value="Generate bridge address"
          onClick={generateBridgeAddress}
        />
      </div>
      {bridgeAddress && (
        <div className="overflow-y-auto">
          <div className="generate-note">
            Please send your {PREFIX} to the address below.
          </div>
          <table className="generate-table">
            <tbody>
              <tr>
                <th>Bridge address:</th>
                <td>
                  {bridgeAddress}{" "}
                  <button
                    className="relative tooltip-trigger"
                    onClick={() => {
                      setCopy(COPIED_TEXT);
                      navigator.clipboard.writeText(bridgeAddress);
                    }}
                    onMouseLeave={() => setCopy(COPY_TEXT)}
                  >
                    Copy
                    <Tooltip>{copy}</Tooltip>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </Page>
  );
};

export default Generate;
