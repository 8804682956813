/* eslint-disable */

const CHARSET = "qpzry9x8gf2tvdw0s3jn54khce6mua7l";

function bech32_polymod(values) {
  let generator = [0x3b6a57b2, 0x26508e6d, 0x1ea119fa, 0x3d4233dd, 0x2a1462b3];
  let chk = 1;
  for (let value in values) {
    value = values[value];
    let top = chk >> 25;
    chk = ((chk & 0x1ffffff) << 5) ^ value;
    for (let i = 0; i < 5; i++) {
      if ((top >> i) & 1) {
        chk ^= generator[i];
      } else {
        chk ^= 0;
      }
    }
  }
  return chk;
}

function bech32_hrp_expand(hrp) {
  let arr = [];
  for (let x in hrp) {
    x = hrp[x];
    arr.push(ord(x) >> 5);
  }
  arr.push(0);
  for (let x in hrp) {
    x = hrp[x];
    arr.push(ord(x) & 31);
  }
  return arr;
}

const M = 0x2bc830a3;

function bech32_create_checksum(hrp, data) {
  let values = bech32_hrp_expand(hrp).concat(data);
  let polymod = bech32_polymod(values.concat([0, 0, 0, 0, 0, 0])) ^ M;
  let arr = [];
  for (let i = 0; i < 6; i++) {
    arr.push((polymod >> (5 * (5 - i))) & 31);
  }
  return arr;
}

function bech32_encode(hrp, data) {
  let combined = data.concat(bech32_create_checksum(hrp, data));
  let arr = [hrp, "1"];
  for (let d in combined) {
    d = combined[d];
    arr.push(CHARSET[d]);
  }
  return arr.join("");
}

function ord(str) {
  return str.charCodeAt(0);
}

function convertbits(data, frombits, tobits, pad = true) {
  let acc = 0;
  let bits = 0;
  let ret = [];
  let maxv = (1 << tobits) - 1;
  let max_acc = (1 << (frombits + tobits - 1)) - 1;
  for (let value in data) {
    value = data[value];
    if (value < 0 || value >> frombits) {
      throw "Invalid Value";
    }
    acc = ((acc << frombits) | value) & max_acc;
    bits += frombits;
    while (bits >= tobits) {
      bits -= tobits;
      ret.push((acc >> bits) & maxv);
    }
  }
  if (pad) {
    if (bits) {
      ret.push((acc << (tobits - bits)) & maxv);
    }
  } else if (bits >= frombits || (acc << (tobits - bits)) & maxv) {
    throw "Invalid bits";
  }
  return ret;
}

function encode_puzzle_hash(puzzle_hash, prefix) {
  return bech32_encode(prefix, convertbits(puzzle_hash, 8, 5));
}

export function hex_to_bytes(hex) {
  if (hex == null) {
    throw "Argument hex of method hex_to_bytes is required and does not have a default value.";
  }
  let bytes = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    bytes[i / 2] = parseInt(hex.substring(i, i + 2), 16);
  }
  return bytes;
}

export function puzzle_hash_to_address(puzzle_hash, prefix = "xch") {
  if (puzzle_hash.indexOf("0x") === 0) {
    puzzle_hash = puzzle_hash.substring(2);
  }
  return encode_puzzle_hash(hex_to_bytes(puzzle_hash), prefix);
}
