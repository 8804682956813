import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./Transaction.css";
import { transactionApi } from "../../api/transactionApi";
import { transactionSerializer } from "../../serializers/transactionSerializer";
import { fromWei } from "../../helpers/web3Helper";
import {
  EVM_ADDRESS,
  EVM_TRANSACTION,
  INFO_TX,
  POST_ADDRESS,
  POST_BLOCK,
  POST_TRANSACTION,
  STATUSES,
  SYMBOL,
} from "../../config";
import Page from "../Page/Page";
import Label from "../Label/Label";
import BridgeNow from "../BridgeNow/BridgeNow";
import { executionThresholdRatio } from "../../helpers/thresholdHelper";

const Transaction = () => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const { hash } = useParams();

  const getTransaction = useCallback(async () => {
    setLoading(true);
    const newTransaction = await transactionApi(hash);
    setTransaction(transactionSerializer(newTransaction));
    setLoading(false);
  }, [hash]);

  useEffect(() => {
    getTransaction();
  }, [getTransaction]);

  return (
    <Page headline="Transaction details" breadcrumb info={INFO_TX}>
      <BridgeNow />
      {loading ? (
        "Loading..."
      ) : transaction ? (
        <div className="overflow-y-auto">
          <table className="transaction-table">
            <tbody>
              <tr>
                <th>Proposition</th>
                <td>{transaction.proposition}</td>
              </tr>
              <tr>
                <th>Coin hash:</th>
                <td>
                  <a
                    href={POST_TRANSACTION.replace(
                      /<.*>/,
                      transaction.coinHash
                    )}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {transaction.coinHash}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Height</th>
                <td>
                  <a
                    href={POST_BLOCK.replace(/<.*>/, transaction.height)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {transaction.height}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Amount</th>
                <td>
                  {fromWei(transaction.amount)} {SYMBOL}
                </td>
              </tr>
              <tr>
                <th>Sender</th>
                <td>
                  <a
                    href={POST_ADDRESS.replace(/<.*>/, transaction.sender)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {transaction.sender}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Receiver</th>
                <td>
                  <a
                    href={EVM_ADDRESS.replace(/<.*>/, transaction.receiver)}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {transaction.receiver}
                  </a>
                </td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <Label value={transaction.status}>
                    {STATUSES[transaction.status]}
                  </Label>
                </td>
              </tr>
              {transaction.status !== "executed" && (
                <>
                  <tr>
                    <th>Execution progress ratio</th>
                    <td>
                      {executionThresholdRatio(
                        transaction.validators.filter(
                          (validator) => validator.attestationTxHash
                        ).length,
                        transaction.validators.length,
                        transaction.executionThresholdRatio.numerator,
                        transaction.executionThresholdRatio.denominator
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>Validators</th>
                    <td>
                      <table className="validators-table">
                        <thead>
                          <tr>
                            <th>Address</th>
                            <th>Attestation tx hash</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transaction.validators.map((validator, index) => (
                            <tr key={index}>
                              <td>
                                <a
                                  href={EVM_ADDRESS.replace(
                                    /<.*>/,
                                    validator.address
                                  )}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {validator.address}
                                </a>
                              </td>
                              <td>
                                <a
                                  href={EVM_TRANSACTION.replace(
                                    /<.*>/,
                                    "0x" + validator.attestationTxHash
                                  )}
                                  target="_blank"
                                  rel="noreferrer noopener"
                                >
                                  {validator.attestationTxHash &&
                                    "0x" + validator.attestationTxHash}
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th>Validator count</th>
                    <td>{transaction.validators.length}</td>
                  </tr>
                  <tr>
                    <th>Attestation count</th>
                    <td>
                      {
                        transaction.validators.filter(
                          (validator) => validator.attestationTxHash
                        ).length
                      }
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        "No transaction found!"
      )}
    </Page>
  );
};

export default Transaction;
