import { request } from "../helpers/requestHelper";
import { BACKEND_URL, DEBUG } from "../config";

export const transactionApi = async (coinHash) => {
  try {
    const response = await request(`${BACKEND_URL}tx/${coinHash}`, {
      credentials: "omit",
    });
    if (response.ok && response.json) {
      DEBUG && console.debug("transactionApi.ok", response.json);
      return response.json;
    } else {
      DEBUG && console.debug("transactionApi.error", response);
    }
  } catch (e) {
    DEBUG && console.error("transactionApi.exception", e);
  }
  return null;
};
