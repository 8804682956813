import Logo from "../Logo/Logo";
import "./Header.css";
import Container from "../Container/Container";

const Header = () => {
  return (
    <div className="header-container">
      <Container>
        <div className="header">
          <Logo />
        </div>
      </Container>
    </div>
  );
};

export default Header;
