import { useState } from "react";

import "./App.css";
import {
  DEFAULT_PAGINATION,
  PaginationContext,
} from "../../contexts/pageContext";
import Header from "../Header/Header";
import Routing from "../Routing/Routing";

function App() {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  return (
    <PaginationContext.Provider value={{ pagination, setPagination }}>
      <div className="app">
        <Header />
        <Routing />
      </div>
    </PaginationContext.Provider>
  );
}

export default App;
