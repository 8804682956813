import { Route, Routes, Navigate } from "react-router-dom";
import Transactions from "../Transactions/Transactions";
import Transaction from "../Transaction/Transaction";
import Generate from "../Generate/Generate";
import { useContext } from "react";
import { PaginationContext } from "../../contexts/pageContext";

const Routing = () => {
  const { pagination } = useContext(PaginationContext);

  return (
    <Routes>
      <Route path="/txs/:ps/:p" element={<Transactions />} />
      <Route path="/tx/:hash" element={<Transaction />} />
      <Route path="/bridge" element={<Generate />} />
      <Route
        path="*"
        element={
          <Navigate to={`/txs/${pagination.ps}/${pagination.p}`} replace />
        }
      />
    </Routes>
  );
};

export default Routing;
