import "./Page.css";
import Container from "../Container/Container";
import { Link } from "react-router-dom";

const Page = ({ children, headline, breadcrumb = false, info }) => {
  return (
    <Container>
      <div className="page">
        <div className="page-headline">
          {breadcrumb && (
            <>
              <Link to="/txs">Transactions</Link>&rsaquo;
            </>
          )}
          <h1>{headline}</h1>
        </div>
        {info && <div className="page-info">{info}</div>}
        <div className="page-content">{children}</div>
      </div>
    </Container>
  );
};

export default Page;
