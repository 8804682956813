import { createContext } from "react";

export const DEFAULT_PAGINATION = {
  ps: "25",
  p: "1",
};

export const PaginationContext = createContext({
  pagination: DEFAULT_PAGINATION,
  setPagination: () => {},
});
