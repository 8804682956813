import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import "./Transactions.css";
import { transactionsApi } from "../../api/transactionsApi";
import { transactionsSerializer } from "../../serializers/transactionsSerializer";
import { addressShortener } from "../../helpers/addressHelper";
import { hashShortener } from "../../helpers/hashHelper";
import { fromWei } from "../../helpers/web3Helper";
import {
  BACKEND_URL,
  EVM_ADDRESS,
  INFO_TXS,
  POST_ADDRESS,
  POST_BLOCK,
  POST_TRANSACTION,
  STATUSES,
  SYMBOL,
} from "../../config";
import Page from "../Page/Page";
import Label from "../Label/Label";
import BridgeNow from "../BridgeNow/BridgeNow";
import { executionThresholdRatio } from "../../helpers/thresholdHelper";
import Pagination from "../Pagination/Pagination";
import { PaginationContext } from "../../contexts/pageContext";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { ps, p } = useParams();
  const navigate = useNavigate();
  const { setPagination } = useContext(PaginationContext);

  const pageSize = ps * 1;
  const page = p * 1;
  const lastPage = Math.ceil(total / pageSize);

  const getTransactions = useCallback(async () => {
    setLoading(true);
    const newTransactions = await transactionsApi({ page, pageSize });
    setTransactions(transactionsSerializer(newTransactions?.txs || []));
    setTotal(newTransactions?.totalTxs || 0);
    setLoading(false);
  }, [page, pageSize]);

  useEffect(() => {
    getTransactions();
  }, [getTransactions]);

  useEffect(() => {
    localStorage.setItem("pageSize", ps);
    localStorage.setItem("page", p);
    setPagination({ ps, p });
  }, [ps, p, setPagination]);

  const changePageSize = (e) => {
    navigate(`/txs/${e.target.value}/1`);
  };

  return (
    <Page headline="Transactions" info={INFO_TXS}>
      <div className="transactions-header">
        <div className="transactions-total">
          {transactions.length > 0 && (
            <div>
              <div>Total transactions: {total}</div>
              <div>
                <a
                  href={`${BACKEND_URL}txs-csv`}
                  download
                  className="download-csv"
                >
                  Download all data as csv
                </a>
              </div>
            </div>
          )}
          {loading && <div>Loading...</div>}
        </div>
        <BridgeNow />
        {transactions.length > 0 && (
          <Pagination page={page} pageSize={pageSize} lastPage={lastPage} />
        )}
      </div>
      {transactions.length > 0 ? (
        <div className="overflow-y-auto">
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Proposition</th>
                <th>Coin hash</th>
                <th>Height</th>
                <th>Amount</th>
                <th>Sender</th>
                <th>Receiver</th>
                <th>Status</th>
                <th>Execution progress ratio</th>
                <th>Validator count</th>
                <th>Attestation count</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={index}>
                  <td title={transaction.proposition}>
                    <Link to={"/tx/" + transaction.proposition}>
                      {hashShortener(transaction.proposition)}
                    </Link>
                  </td>
                  <td title={transaction.coinHash}>
                    <a
                      href={POST_TRANSACTION.replace(
                        /<.*>/,
                        transaction.coinHash
                      )}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {hashShortener(transaction.coinHash)}
                    </a>
                  </td>
                  <td>
                    <a
                      href={POST_BLOCK.replace(/<.*>/, transaction.height)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {transaction.height}
                    </a>
                  </td>
                  <td className="amount">
                    {fromWei(transaction.amount)} {SYMBOL}
                  </td>
                  <td title={transaction.sender}>
                    <a
                      href={POST_ADDRESS.replace(/<.*>/, transaction.sender)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {addressShortener(transaction.sender)}
                    </a>
                  </td>
                  <td title={transaction.receiver}>
                    <a
                      href={EVM_ADDRESS.replace(/<.*>/, transaction.receiver)}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {addressShortener(transaction.receiver)}
                    </a>
                  </td>
                  <td>
                    <Link to={"/tx/" + transaction.proposition}>
                      <Label value={transaction.status}>
                        {STATUSES[transaction.status]}
                      </Label>
                    </Link>
                  </td>
                  {transaction.status !== "executed" ? (
                    <td>
                      {executionThresholdRatio(
                        transaction.attestationCount,
                        transaction.validatorCount,
                        transaction.executionThresholdRatio.numerator,
                        transaction.executionThresholdRatio.denominator
                      )}
                    </td>
                  ) : (
                    <td></td>
                  )}
                  <td>{transaction.validatorCount}</td>
                  <td>{transaction.attestationCount}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && <div>No transaction found!</div>
      )}
      {transactions.length > 0 && (
        <div className="transactions-footer">
          <div className="transactions-size">
            <select value={pageSize} onChange={changePageSize}>
              {[10, 25, 50, 100].map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </div>
          <Pagination page={page} pageSize={pageSize} lastPage={lastPage} />
        </div>
      )}
    </Page>
  );
};

export default Transactions;
