export const request = async (
  url,
  {
    method = "GET",
    body = null,
    bodyType = "json",
    query = null,
    credentials = "include",
    mode = "cors",
    headers = {},
    responseType = "json",
  } = {}
) => {
  const options = {
    method,
    headers,
    credentials,
    mode,
  };

  if (body) {
    if (bodyType === "json") {
      options.body = JSON.stringify(body);
    } else if (bodyType === "form-data") {
      const formData = new FormData();
      const keys = Object.keys(body);
      keys.forEach((key) => {
        formData.append(key, body[key]);
      });
      options.body = formData;
    } else {
      options.body = body;
    }
  }

  const uri =
    url +
    (query
      ? (url.indexOf("?") === -1 ? "?" : "&") +
        Object.keys(query)
          .map((key) => key + "=" + query[key])
          .join("&")
      : "");

  try {
    const response = await fetch(uri, options);
    const headers = {};

    response.headers.forEach((header, index) => {
      headers[index] = header;
    });

    const result = {
      status: response.status,
      statusText: response.statusText,
      ok: response.ok,
      headers,
    };
    if (responseType) {
      result[responseType] = await response[responseType]();
    }

    return result;
  } catch (e) {
    return {
      status: 500,
      statusText: "Failed to fetch",
      ok: false,
    };
  }
};
