import Web3 from "web3";
import { UNIT } from "../config";

const web3 = new Web3(Web3.givenProvider);

export const toChecksumAddress = (address) => {
  return web3.utils.toChecksumAddress(address);
};

export const fromWei = (value) => {
  return web3.utils.fromWei(value + "", UNIT);
};

export const toWei = (value) => {
  return web3.utils.toWei(value + "", UNIT);
};
