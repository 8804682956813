import { request } from "../helpers/requestHelper";
import { BACKEND_URL, DEBUG } from "../config";

export const transactionsApi = async ({ page = 1, pageSize = 25 } = {}) => {
  try {
    const response = await request(
      `${BACKEND_URL}txs?p=${page}&ps=${pageSize}`,
      {
        credentials: "omit",
      }
    );
    if (response.ok && response.json) {
      DEBUG && console.debug("transactionsApi.ok", response.json);
      return response.json;
    } else {
      DEBUG && console.debug("transactionsApi.error", response);
    }
  } catch (e) {
    DEBUG && console.error("transactionsApi.exception", e);
  }
  return null;
};
