export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const DEBUG = !!process.env.REACT_APP_DEBUG;
export const MAGIC = process.env.REACT_APP_MAGIC;
export const PREFIX = process.env.REACT_APP_PREFIX;
export const SYMBOL = process.env.REACT_APP_SYMBOL;
export const UNIT = process.env.REACT_APP_UNIT;
export const POST_TRANSACTION = process.env.REACT_APP_POST_TRANSACTION;
export const POST_ADDRESS = process.env.REACT_APP_POST_ADDRESS;
export const EVM_TRANSACTION = process.env.REACT_APP_EVM_TRANSACTION;
export const EVM_ADDRESS = process.env.REACT_APP_EVM_ADDRESS;
export const POST_BLOCK = process.env.REACT_APP_POST_BLOCK;
export const INFO_TXS = process.env.REACT_APP_INFO_TXS;
export const INFO_TX = process.env.REACT_APP_INFO_TX;
export const INFO_BRIDGE = process.env.REACT_APP_INFO_BRIDGE;

export const STATUSES = {
  "waiting-for-attestations": "Waiting for attestation",
  "waiting-for-execution": "Waiting for execution",
  executed: "Executed",
};
